import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const Registration = (props) => (
    <Layout>
        <Helmet>
            <title>2024 CHCSD Registration</title>
            <meta name="description" content="Registration Page" />
        </Helmet>

        <div id="main" className="alt">

            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Attendee Registration is Open!</h1>
                    </header>
                    <p>One registration covers up to two parents and their first-generation minor children. Adult unmarried children may be included if they are not a parent.</p>
                    <p>No refunds after Early Bird Registration closes.</p>
                    <p>Your TEACHSD membership not only gives your family a discount to the conference, but it also helps to cultivate, protect and promote Christian homeschooling across the state of South Dakota. Through the diligent work of its committees, TEACHSD is working to build and support homeschool families. Join our growing community of supporters!</p>
                    <p></p>
                    <p><h3>Code of Conduct</h3></p>
                    <p>The conference is designed to be a family safe and family friendly event. However, families who are attending must understand that the conduct and behavior of parents and children are representative of the South Dakota homeschool community at large. We have seen an alarming rise in disruptive and disrespectful behavior by unattended children in activities and the Vendor Hall. Please prepare your children to conduct themselves with decorum, decency and respectfulness while attending the conference and participating in activities. Children who are unable to listen and follow instructions will be removed from activities and asked to return to their parent's care. Children found running or being rambunctious in the Vendor Hall will be required to do the same.</p>
                    <p></p>
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <div className="table-wrapper">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><a href="" target="_blank" className="button special fit">Member Registration</a>
                                                <ul>
                                                    <li>Click the link <strong>sent to your membership email</strong> to access your membership registration options. <i>If you did not receive a member registration link email, your membership requires renewal before the conference. Please click "RENEW YOUR MEMBERSHIP & REGISTER" below.</i></li>
                                                    <li>Member Early Bird Registration: $20 (Ends 04/19/2024)</li>
                                                    <li>Member Registration: $35</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><a href="https://www.eventbrite.com/e/chcsd-2024-registration-848118233467" target="_blank" className="button special fit">Renew your Membership & Register</a>
                                                <ul>
                                                    <li>Click the link above and complete the registration process.</li>
                                                    <li>Membership Renewal & Early Bird Registration: $45 (Ends 04/19/2024)</li>
                                                    <li>Membership Renewal & Registration: $60</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><a href="https://www.eventbrite.com/e/chcsd-2024-registration-848118233467" target="_blank" className="button special fit">Become a Member & Register</a>
                                                <ul>
                                                    <li>Click the link above and complete the registration process.</li>
                                                    <li>Membership & Early Bird Registration: $45 (Ends 04/19/2024)</li>
                                                    <li>Membership & Registration: $60</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><a href="https://www.eventbrite.com/e/chcsd-2024-registration-848118233467" target="_blank" className="button special fit">General Registration</a>
                                                <ul>
                                                    <li>Click the link above and complete the registration process.</li>
                                                    <li>General Registration: $65</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><a href="https://www.eventbrite.com/e/chcsd-2024-registration-848118233467" target="_blank" className="button special fit">Complimentary Registration</a>
                                                <ul>
                                                    <li>Available at Check-in desk at the conference.</li>
                                                    <li>Non-homeschooling Grandparents: FREE</li>
                                                    <li>Non-homeschooling Pastor/Minister: FREE</li>
                                                    <li>Current Legislators (please wear badges): FREE</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>                </div>
            </section>
        </div>
    </Layout>
)

export default Registration